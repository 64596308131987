import React from "react"
import { Link } from "gatsby"
import { rhythm } from "../utils/typography"
import ToggleMode from "../components/toggle-mode";

const Navigation = () => {
    return (
        <div className="navigation" style={{
            marginBottom: `${rhythm(2)}`,
        }}>
            <ToggleMode />    
            <ul style={{
                listStyle: `none`,
                display: `flex`,
                justifyContent: `space-evenly`,
                margin: `0`
                }}>
                <li><Link to="/">Home</Link></li>
                <li><Link to="https://www.linkedin.com/in/tammyritterskamp/" target="_blank">Work History</Link></li>
                <li><Link to="/blog">Blog</Link></li>
            </ul>
        </div>
    )
}

export default Navigation;
