import React, { useEffect } from 'react';

const ToggleMode = () => {

    useEffect(() => {
        const toggle = document.querySelector(".toggle-mode");
        const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)").matches;
        const localStorageValue = localStorage.getItem("siteMode") ? localStorage.getItem("siteMode") : null;
    
        // First, check for device settings
        if (prefersDarkScheme === true) {
            toggle.setAttribute("aria-checked", "true");
        } else {
            toggle.setAttribute("aria-checked", "false");
        }

        // Then, check for localStorage
        if (localStorageValue === "dark-theme") {
            document.body.classList.add("dark-theme");
            document.body.classList.remove("light-theme");
            toggle.setAttribute("aria-checked", true);
        } else if (localStorageValue === "light-theme") {
            document.body.classList.add("light-theme");
            document.body.classList.remove("dark-theme");
            toggle.setAttribute("aria-checked", false);
        }
    }, []);
    
    const toggleModeClick = (e) => {
        const parent = e.target.parentNode;

        if (document.body.classList.contains("dark-theme")) {
            document.body.classList.add("light-theme");    
            document.body.classList.remove("dark-theme");
            localStorage.setItem("siteMode", "light-theme");
            parent.setAttribute("aria-checked", "false");
        } else {
            document.body.classList.add("dark-theme");    
            document.body.classList.remove("light-theme");
            localStorage.setItem("siteMode", "dark-theme");  
            parent.setAttribute("aria-checked", "true");
        }      
    }


    return (
        <div style={{
            position: `absolute`,
            top: `15px`,
            right: `20px`,
        }} className="toggle-mode" onClick={toggleModeClick} onKeyDown={toggleModeClick} role="checkbox" aria-checked="false" aria-label="Toggle dark mode" tabIndex="0">  
            <div className="toggle__track"></div>
            <div className="toggle__slider fas fa-moon"></div>      
      </div>
    )

}

export default ToggleMode;

